import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import searchVideosReducer from './searchVideosSlice';
import latestVideosReducer from './latestVideosSlice';
import affinityReducer from './affinitySlice';
import cartReducer from './cartSlice';
import customerReducer from './customerSlice';
import attemptVideoReducer from './attemptVideoSlice';
import navigationReducer from './navigationSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  search: searchVideosReducer,
  latest: latestVideosReducer,
  affinity: affinityReducer,
  cart: cartReducer,
  customer: customerReducer,
  attempt: attemptVideoReducer,
  navigation: navigationReducer,
});

export default rootReducer;
