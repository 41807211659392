import { lazy, Suspense } from 'react';

const LoginPage = lazy(() => import('../pages/Auth/LoginPage'));
const RecoverPage = lazy(() => import('../pages/Auth/RecoverPage'));
const RegisterPage = lazy(() => import('../pages/Auth/RegisterPage'));
const ResetPage = lazy(() => import('../pages/Auth/ResetPage'));

export const AuthRoutes = [
  {
    path: 'sign-in',
    element: (
      <Suspense fallback={<></>}>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: 'sign-up',
    element: (
      <Suspense fallback={<></>}>
        <RegisterPage />
      </Suspense>
    ),
  },
  {
    path: 'recover-customer',
    element: (
      <Suspense fallback={<></>}>
        <RecoverPage />
      </Suspense>
    ),
  },
  {
    path: 'reset-customer-password',
    element: (
      <Suspense fallback={<></>}>
        <ResetPage />
      </Suspense>
    ),
  },
];
