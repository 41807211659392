import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Customer } from '../../models/customer.interface';
import { initialState } from '../types/customerTypes';

const cartSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerInfo(state, action: PayloadAction<Customer>) {
      state.customerInfo = action.payload;
    },
    clearCustomerInfo(state) {
      state.customerInfo = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        orders: [],
      };
    },
  },
});

export const { setCustomerInfo, clearCustomerInfo } = cartSlice.actions;
export default cartSlice.reducer;
