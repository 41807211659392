import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import logo from '../../../../../assets/logo-thecodeworld.png';
import starSvg from '../../../../../assets/Star.svg';

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={(theme) => ({
            bgcolor: theme.palette.background.dark,
            borderBottom: `2px solid ${theme.palette.background.default}`,
            height: '6.125rem',
            padding: '2rem 3.75rem', // TODO to see
          })}
        >
          <IconButton size="small" edge="start" color="inherit" sx={{ mr: 0, padding: 0 }}>
            <img src={starSvg} alt="Star Icon" style={{ width: 34, height: 34 }} />
          </IconButton>
          <Box
            component="img"
            src={logo}
            alt="The Code Word"
            sx={{
              display: { xs: 'none', sm: 'block' },
              height: 21,
              width: 216,
              ml: 1,
            }}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
