import { lazy, Suspense } from 'react';
const Licensing = lazy(() => import('../pages/Licensing'));

export const LicensingRoutes = [
  {
    path: 'licensing',
    element: (
      <Suspense fallback={<></>}>
        <Licensing />
      </Suspense>
    ),
  },
];
