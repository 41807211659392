export interface Asset {
  url: string;
  thumbnailUrl: string;
  startPoint: string;
  matchType: string;
}

export interface Video {
  id: string;
  url: string;
  title: string;
  description: string;
  tags: string;
  thumbnailUrl: string;
  width?: string,
  height?: string,
  format?: string,
  type?: string,
  duration: number,
  assets: Asset[];
}


export interface VideosSearchState {
  videos: Video[];
  searchText: string,
  expireAt: string
}

const initialState: VideosSearchState = {
  videos: [],
  searchText: '',
  expireAt: ''
};

export { initialState };
