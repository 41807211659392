import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, Video } from '../types/searchTypes';

const searchVideosSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setVideos(state, action: PayloadAction<Video[]>) {
      state.videos = action.payload;
    },
    clearVideos(state) {
      state.videos = [];
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    clearSearchText(state) {
      state.searchText = '';
    },
    setExpireDate(state, action: PayloadAction<string>) {
      state.expireAt = action.payload;
    },
    clearExpireDate(state) {
      state.expireAt = '';
    },
    clearSearch(state) {
      state.videos = [];
      state.searchText = '';
      state.expireAt = '';
    },
  },
});

export const { setVideos, clearVideos, setSearchText, clearSearchText, clearSearch, setExpireDate, clearExpireDate } = searchVideosSlice.actions;
export default searchVideosSlice.reducer;
