const DEFAULT_AFFINITY = 0.2;
const MAX_LINES = 3;
const AVERAGE_CHARACTERS_PER_LINE = 60;
const MIN_TEXT_LENGTH = 3;
const MAX_CHARACTERS = MAX_LINES * AVERAGE_CHARACTERS_PER_LINE;

export {
  DEFAULT_AFFINITY,
  MAX_LINES,
  AVERAGE_CHARACTERS_PER_LINE,
  MIN_TEXT_LENGTH,
  MAX_CHARACTERS,
};
