import React from 'react';
import GeneralMessage from '../../components/GeneralMessage';
import { PAGE_NOT_FOUND_MSG, PAGE_NOT_FOUND_TITLE } from '../../constants/strings.constant';

const NotFound: React.FC = () => {
  return (
      <GeneralMessage
        title={PAGE_NOT_FOUND_TITLE}
        message={PAGE_NOT_FOUND_MSG}
        displayBackHome={true}
        displayOtherIcon={true}
      />
  );
};

export default NotFound;
