import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import { BaseLayout } from '../App';
import CheckoutHeader from '../pages/Checkout/components/common/Header';

const Payment = lazy(() => import('../pages/Checkout/components/payment'));
const ThankYou = lazy(() => import('../pages/Checkout/components/thankyou'));

const CheckoutLayout = () => {
  return (
    <BaseLayout>
      <CheckoutHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '86vh',
        }}
      >
        <Box
          component="main"
          sx={{
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </BaseLayout>
  );
};

const CheckoutRoutes = {
  path: 'checkout',
  element: <CheckoutLayout />,
  children: [
    {
      path: 'payment',
      element: (
        <Suspense fallback={<div />}>
          <Payment />
        </Suspense>
      ),
    },
    {
      path: 'thanks',
      element: (
        <Suspense fallback={<div />}>
          <ThankYou />
        </Suspense>
      ),
    },
  ],
};

export default CheckoutRoutes;
