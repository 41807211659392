import { Video } from "./searchTypes";

export interface VideosLatestState {
  videos: Video[];
}

const initialState: VideosLatestState = {
  videos: [],
};

export { initialState };
