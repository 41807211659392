import { lazy, Suspense } from 'react';

const VideoDetail = lazy(() => import('../pages/VideoDetail'));

export const VideoDetailRoutes = [
  {
    path: 'video-detail/:id',
    element: (
      <Suspense fallback={<></>}>
        <VideoDetail />
      </Suspense>
    ),
  },
];
