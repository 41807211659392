import type { Customer } from '../../models/customer.interface';

export interface CustomerState {
  customerInfo: Customer;
}

const initialState: CustomerState = {
  customerInfo: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    orders: [],
  },
};

export { initialState };
