import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VideoState {
  attemptedVideoId: string | null;
}

const initialState: VideoState = {
  attemptedVideoId: null,
};

const videoSlice = createSlice({
  name: 'attempt',
  initialState,
  reducers: {
    setAttemptedVideoId(state, action: PayloadAction<string>) {
      state.attemptedVideoId = action.payload;
    },
    clearAttemptedVideoId(state) {
      state.attemptedVideoId = null;
    },
  },
});

export const { setAttemptedVideoId, clearAttemptedVideoId } = videoSlice.actions;
export default videoSlice.reducer;
