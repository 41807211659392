import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Order } from '../../models/order.interface';
import { initialState, type PaymentType } from '../types/cartTypes';

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setDraftOrder(state, action: PayloadAction<Order | undefined>) {
      state.draftOrder = action.payload;
    },
    clearDraftOrder(state) {
      state.draftOrder = undefined;
    },
    setPurchasedOrder(state, action: PayloadAction<Order | undefined>) {
      state.purchasedOrder = action.payload;
    },
    clearPurchasedOrder(state) {
      state.purchasedOrder = undefined;
    },
    setPaymentType(state, action: PayloadAction<PaymentType>) {
      state.paymentType = action.payload;
    },
    clearPaymentType(state) {
      state.paymentType = 'creditCard';
    },
  },
});

export const {
  setDraftOrder,
  clearDraftOrder,
  setPurchasedOrder,
  clearPurchasedOrder,
  setPaymentType,
  clearPaymentType,
} = cartSlice.actions;
export default cartSlice.reducer;
