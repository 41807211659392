import { Box } from "@mui/material";

interface BubbleProps {
    position: { 
        left: number | string; 
        top: number | string 
    };
    size: number | string;
}

const Bubble: React.FC<BubbleProps> = ({ position, size }) => {
    
    return (
        <>
            <style>
            {`
                @keyframes bubbleAnimation {
                    0% {
                        transform: scale(1) rotate(0deg);
                    }
                    50% {
                        transform: scale(1.1) rotate(10deg);
                    }
                    100% {
                        transform: scale(1) rotate(0deg);
                    }
                }
            `}
            </style>            
            <Box
                className="bubble"
                sx={{
                    position: 'absolute',
                    left: typeof position.left === 'string' ? position.left: `${position.left}px`,
                    top: typeof position.left === 'string' ? position.top: `${position.top}px`,
                    width: typeof size === 'string' ? size : `${size}px`,
                    height: typeof size === 'string' ? size : `${size}px`,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.4), rgba(106, 130, 251, 0.5) 60%, rgba(252, 92, 125, 0.7) 80%)', /* Gradient for depth */
                    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.5), inset 0 2px 5px rgba(255, 255, 255, 0.3)', /* Shadow for depth */                    
                    transition: 'top 0.1s ease-in-out',
                    animation: 'bubbleAnimation 3s infinite' /* Animation for dynamic effect */                
                }}
            />
        </>
    );
};

export default Bubble;
