import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  expiresAt: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  expiresAt: null,
  firstName: null,
  lastName: null,
  email: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<{ expiresAt: string }>) => {
      state.isAuthenticated = true;
      state.expiresAt = action.payload.expiresAt;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.expiresAt = null;
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
