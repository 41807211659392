import type { Order } from '../../models/order.interface';

export type PaymentType = 'paypal' | 'creditCard' | 'bankDeposit';

export interface CartState {
  draftOrder: Order | undefined;
  purchasedOrder: Order | undefined;
  paymentType: PaymentType;
}

const initialState: CartState = {
  draftOrder: undefined,
  purchasedOrder: undefined,
  paymentType: 'creditCard',
};

export { initialState };
