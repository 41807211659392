import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import theme from '../../themes/theme';
import { GeneralMessageProps } from './types/generalMessageTypes';
import { HOME_TEXT } from '../../constants/strings.constant';

const GeneralMessage = ({
    title,
    message,
    displayBackHome = false,
    displayOtherIcon = false
  }: GeneralMessageProps) => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  }

  return (
    <Box
      sx={{
        marginTop: '12rem',
        width: '100%',
        padding: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
        alignSelf: 'stretch'
      }}>
      {displayOtherIcon ? <NotInterestedOutlinedIcon sx={{
        fontSize: '3rem'
      }}/> : <SearchOffIcon sx={{
        fontSize: '3rem'
      }}/>}
      <Typography 
        sx={{ 
          textTransform: 'uppercase', 
          fontFamily: "Bebas Neue",
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: '3rem',
          color: '#E5ECFF'
        }}>
        {title}
      </Typography>
      <Typography 
        sx={{

        }}>
        { message } 
      </Typography>
      {
        displayBackHome && (
          <Box 
            sx={{
              display: 'flex',
              padding: '0.75rem 1rem',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '0.5rem',
              borderRadius: '2.5rem',
              border: `1px solid ${theme.palette.primary.main}`,
              background: `${theme.palette.primary.main}`
            }}
            onClick={goHome}>
            <HomeOutlined />
            <Typography
              sx={{
                color: `${theme.palette.primary.contrastText}`,
                fontSize: '1.175rem',
                fontWeight: '700;',
                textTransform: 'uppercase',
                cursor: 'pointer',
              }}>
              {HOME_TEXT}
            </Typography>
          </Box>
        )
      }
    </Box>
  );
};

export default GeneralMessage;
